<template>
  <div>

    <div style="display: flex;flex-wrap: wrap;justify-content: center;align-items: center">
      <div v-for="(item,i) in data" :key="item.id" style="margin: 15px">
        <a-card hoverable style="width: 200px;border-radius: 9px" :bodyStyle="{height:'90px'}"
                @click="editMaterial(item.id,i)" @click.right="delIt(item.id)">
          <div slot="cover" style="padding: 25px;height:180px">
            <img
                :alt="item.medianame"
                :title="item.medianame"
                width="100%"
                style="border-top-right-radius: 9px;border-top-left-radius: 9px"
                :src="item.path"
            />
          </div>


          <a-card-meta :title="item.medianame" :description="item.create_time">

          </a-card-meta>
        </a-card>


        <a-modal v-model="modal_status.show[i]" title="素材编辑" @ok="editMaterialModalOk(item.id,i)" okText="修改"
                 cancelText="取消修改"
                 @cancel="editMaterialModalCancel(i)">
          <div style="display: flex;justify-content: center;flex-wrap: wrap">
            <img :src="modal_status.editObj.path" alt="" style="border-radius: 12px">
            <label for="loadBtn" class="loadBtn"
                   style="width: 300px;margin-top: 15px;height: 30px;text-align: center;line-height: 30px;font-size: 18px;font-weight: 600;">
              上传图片
              <input type="file" id="loadBtn" accept="image/*" style="display: none" @change="uploadMaterialEdit">

            </label>
          </div>
          <div style="margin: 16px 0 ">
            <a-input addon-before="名称:" v-model="modal_status.editObj.medianame"/>
          </div>
          <div style="margin-bottom: 16px">
            <a-input addon-before="路径:" v-model="modal_status.editObj.path" disabled=""/>
          </div>
          <div style="margin-bottom: 16px">
            <a-input addon-before="权重:" v-model="modal_status.editObj.index"/>
          </div>
        </a-modal>
      </div>


      <div style="margin: 15px">
        <a-card hoverable style="width: 200px;border-radius: 9px" :bodyStyle="{height:'90px'}"
                @click="isAdd=true">
          <div
              slot="cover"
              style="height: 290px;display: flex;justify-content: center;align-items: center;font-size: 20px;font-weight: 600">
            <div>新增素材</div>
          </div>
        </a-card>
      </div>
    </div>

    <a-modal v-model="isAdd" title="添加素材" @ok="addMaterial" okText="添加"
             cancelText="取消"
             @cancel="isAdd=false">
      <div style="display: flex;justify-content: center;flex-wrap: wrap">
        <img :src="Abj.path" alt="" style="border-radius: 12px">
        <label for="loadBtn" class="loadBtn"
               style="width: 300px;margin-top: 15px;height: 30px;text-align: center;line-height: 30px;font-size: 18px;font-weight: 600;">
          上传图片
          <input type="file" id="loadBtn" accept="image/*" style="display: none" @change="uploadMaterial">

        </label>
      </div>
      <div style="margin: 16px 0 ">
        <a-input addon-before="名称:" v-model="Abj.medianame"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="路径:" v-model="Abj.path" disabled=""/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="权重:" v-model="Abj.index"/>
      </div>
    </a-modal>

    <a-pagination style="float: right;margin-top: 15px;"
                  :default-current="1"
                  v-model="curr"
                  :pageSize="27"
                  :total="this.returnBack.count"
    />

  </div>

</template>

<script>
export default {
  name: "Material",
  data() {
    return {
      loadPath: '',
      curr: 1,
      modal_status: {
        show: [],
        editObj: {}

      },

      isAdd: false,
      Abj: {},

      copyData: {},

      returnBack: {},
      data: []
    }
  },
  methods: {
    addMaterial() {
      this.$axios.post('/Admin/Api/MediaCatalog/addMediaCatalog', {
        ...this.Abj
      }).then(v => {
        this.Abj.id = JSON.parse(v.data.data).id
        this.data.push(this.Abj)
        this.Abj = {}
        this.$message.success(v.data.msg, 1)
      }).catch(err => this.$message.error(err.data))
      this.isAdd = false
    },

    uploadMaterial(e) {
      const formdata = new FormData()
      formdata.append('file', e.target.files[0])
      this.$axios.post('/Admin/Api/uploadImage', formdata).then(v => {
        this.$message.success(v.data.msg)
        this.$set(this.Abj, 'path', JSON.parse(v.data.data).url)
      }).catch(err => this.$message.success(err.msg)
      )
    },

    uploadMaterialEdit(e) {
      const formdata = new FormData()
      formdata.append('file', e.target.files[0])
      this.$axios.post('/Admin/Api/uploadImage', formdata).then(v => {
        this.$message.success(v.data.msg)
        this.$set(this.modal_status.editObj, 'path', JSON.parse(v.data.data).url)
      }).catch(err => this.$message.success(err.msg)
      )
    },

    editMaterialModalOk(v, key) {
      this.$axios.post('/Admin/Api/MediaCatalog/changeMediaCatalog', {
        ...this.modal_status.editObj
      }).then(val => {
        this.$message.success(val.data.msg)
      }).catch(err => {
        this.$message.success(err.msg)
        this.editMaterialModalCancel(key)
      })
      this.$set(this.modal_status.show, key, false)

    },

    editMaterialModalCancel(v) {
      this.$message.warning('取消修改')
      this.$set(this.modal_status.show, v, false)
      Object.assign(this.modal_status.editObj, this.copyData)
    },

    editMaterial(v, key) {
      this.modal_status.editObj = this.data.find(val => val.id == v)
      Object.assign(this.copyData, this.modal_status.editObj)
      this.$set(this.modal_status.show, key, true)
    },

    delIt(id) {
      const self = this
      this.$confirm({
        title: '是否删除该素材',
        content: '此操作不可恢复，只能重新添加',
        okText: '确定删除',
        cancelText: '取消',
        onOk() {
          self.$axios.post('/Admin/Api/MediaCatalog/delMediaCatalog', {
            id
          }).then(v => {
            self.data = self.data.filter(val => val.id != id)
            self.$message.success(v.data.msg)
          }).catch(err => self.$message.error(err.msg))
        },
        onCancel() {
        },
      });
    },

  },
  watch: {
    curr: {
      async handler(n) {
        this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/MediaCatalog/getMediaCatalogList', {
          "page": n,
          "pageCount": 27
        })).data.data)
        this.data = this.returnBack.list
      }
    }
  },
  mounted() {
    window.oncontextmenu = function (e) {
      e.preventDefault();
    };
  },
  async created() {
    this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/MediaCatalog/getMediaCatalogList', {
      "page": 1,
      "pageCount": 27
    })).data.data)
    this.data = this.returnBack.list
  }
}
</script>

<style scoped lang="less">
::v-deep {
  img {
    max-width: 100%;
  }
}

.loadBtn {
  border: 0;
  border-radius: 10px;
  background: #2ec4b6;
  /* 文字变大写 */
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  position: relative;
  /* 添加动画，对元素的 border-radius 添加 3s 的延迟效果 */
  transition: border-radius 3s;
  -webkit-transition: border-radius 3s;
}

.loadBtn:hover {
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>